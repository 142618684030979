<template>
  <!-- ローディング -->
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <div class="row">
    <h3>{{ $t('SalesListsImportView.h3') }}</h3>
  </div>

  <div class="row" style="background-color: #ececec; margin-bottom: 0">
    <div class="column">
      <label for="file-upload" class="form-label" style="margin-top: 60px; text-align: right">
        {{ $t('SalesListsImportView.csv_drag') }}</label>
    </div>
    <div class="column">
      <input ref="file_csv" id="file-upload" type="file" @change="onFileChange" style="margin-top: 50px; width: 240px"
        class="btn btn-primary" />
    </div>
  </div>
  <div class="row" style="background-color: #ececec; margin-top: 0">
    <div class="column"></div>
    <div class="column">
      <label class="form-label" @click="handle_return" style="margin-top: 10px; margin-bottom: 50px; cursor: pointer">
        {{ $t('SalesListsImportView.input_directly') }}</label>
    </div>
  </div>

  <div class="row" style="margin-top: 60px">
    <h3>{{ $t('SalesListsImportView.table_name') }}</h3>
  </div>

  <!-- リスト一覧テーブル(清單清單表) -->
  <div class="row">
    <table class="table table-hover">

      <thead>
        <tr>
          <th scope="auto">{{ $t('SalesListsImportView.field_name') }}</th>
          <th scope="auto"></th>
          <th scope="auto">{{ $t('SalesListsImportView.csv_field') }}csv_field</th>
          <th scope="auto">{{ $t('SalesListsImportView.sample_value') }}</th>
        </tr>
      </thead>

      <tbody>

        <tr>
          <td>{{ $t('SalesListsImportView.company_name') }}</td>
          <td> → </td>
          <td>
            <select v-model="company_name_index" id="resource-type" class="form-select">
              <option value=-1>-</option>
              <option v-for="(item, index) in fields_for_select" :value="index" :key="index">
                {{ item }}</option>
            </select>
          </td>
          <td>{{ fields_for_sample[company_name_index] }}</td>
        </tr>

        <tr>
          <td>{{ $t('SalesListsImportView.company_url') }}</td>
          <td> → </td>
          <td>
            <select v-model="company_url_index" id="resource-type" class="form-select">
              <option value=-1>-</option>
              <option v-for="(item, index) in fields_for_select" :value="index" :key="index">
                {{ item }}</option>
            </select>
          </td>
          <td>{{ fields_for_sample[company_url_index] }}</td>
        </tr>

        <tr>
          <td>{{ $t('SalesListsImportView.person_name') }}</td>
          <td> → </td>
          <td>
            <select v-model="person_name_index" id="resource-type" class="form-select">
              <option value=-1>-</option>
              <option v-for="(item, index) in fields_for_select" :value="index" :key="index">
                {{ item }}</option>
            </select>
          </td>
          <td>{{ fields_for_sample[person_name_index] }}</td>
        </tr>

        <tr>
          <td>{{ $t('SalesListsImportView.email') }}</td>
          <td> → </td>
          <td>
            <select v-model="email_index" id="resource-type" class="form-select">
              <option value=-1>-</option>
              <option v-for="(item, index) in fields_for_select" :value="index" :key="index">
                {{ item }}</option>
            </select>
          </td>
          <td>{{ fields_for_sample[email_index] }}</td>
        </tr>

      </tbody>

    </table>
  </div>

  <div class="row" style="justify-content: center">
    <button @click="import_detail" class="btn btn-primary" style="width: 160px; text-align: center">
      {{ $t('SalesListsImportView.upload_file') }}</button>
  </div>

</template>

<script>
import { defineComponent } from "vue";
import { salesListsApi } from "@/api/forsales_index";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { useRoute } from "vue-router";
import router from "@/router";


export default defineComponent({
  components: {
    LoadingIcon,
  },
  data() {
    const route = useRoute();
    const sales_list_id = parseInt(route.params.salesListID);

    return {
      sales_list_id,
      selectedFile: null,
      fields_for_select: [],
      fields_for_sample: [],
      company_name_index: -1,
      company_url_index: -1,
      company_detail_index: -1,
      person_name_index: -1,
      email_index: -1,
      tel_index: -1,
      contact_url_index: -1,
      mail_text_index: -1,
      isLoading: false,
    };
  },

  created: async function () {
    try {
      const optionResp = await salesListsApi.check_optionAccess();
      console.log(optionResp)
      if (optionResp.message !== "ok") {
        return router.push(`/forsales/options_error`);
      }
    } catch (error) {
      return router.push(`/forsales/options_error`);
    }
  },

  methods:
  {
    async handle_return() {
      this.$router.push(`/forsales/sales_lists/${this.sales_list_id}/sales_lists_details`)
    },

    async import_detail() {
      this.isLoading = true;
      const locale = this.$i18n.locale
      const sales_list_id = this.sales_list_id
      console.log("** sales_list_id:", sales_list_id)
      
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("locale", locale);
      formData.append("sales_list_id", this.sales_list_id);
      formData.append("company_name_index", this.company_name_index);
      formData.append("company_url_index", this.company_url_index);
      formData.append("person_name_index", this.person_name_index);
      formData.append("email_index", this.email_index);

      const resp_create = await salesListsApi.create_saleslist_detail_csv(formData);
      console.log("resp_create:", resp_create)
      
      this.isLoading = false;
      await router.push(`/forsales/sales_lists/${sales_list_id}/sales_lists_details`)
    },

    async uploadFile() {
      if (!this.selectedFile) {
        alert(this.$t('ChatResourceUploadView.no_files_selected'));
        location.reload();
        return;
      }

      console.log("upload file...:", this.selectedFile)

      this.isLoading = true;
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("locale", this.$i18n.locale);
      const csvHeader = await salesListsApi.getCsvHeader(formData);

      // CSVファイル内容チェック
      if (csvHeader.err != "") {
        this.isLoading = false;
        alert(csvHeader.err);
        location.reload();
        return;
      }

      this.fields_for_select = csvHeader.header;
      this.fields_for_sample = csvHeader.data;
      this.isLoading = false;
    },

    onFileChange(e) {
      this.selectedFile = e.target.files[0];
      console.log("this.selectedFile:", this.selectedFile)
      this.uploadFile()
    },
  },
});

</script>

<style scoped>
.row {
  display: flex;
  margin: 15px 5px;
}

.column {
  flex: 1;
}
</style>
