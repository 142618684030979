<script setup>
import {onMounted, reactive, ref} from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { salesListsApi } from "@/api/forsales_index";
//import {userApi} from "@/api";

import { useI18n } from 'vue-i18n'
const { locale } = useI18n();
console.log( "locale:", locale.value)

const router = useRouter();
const route = useRoute();
const sales_list_id = route.params.salesListID;
console.log( "sales_list_id:", sales_list_id)
// const store = useStore()

onMounted( async () => {

  try {
    const optionResp = await salesListsApi.check_optionAccess();
    console.log(optionResp)
    if (optionResp.message !== "ok") {
      return router.push(`/forsales/options_error`);
    }
  } catch (error) {
    return router.push(`/forsales/options_error`);
  }
})

const item = reactive({
  company_name: "",
  person_name: "",
  company_url: "",
  email: "",
  company_detail: "",
  mail_text: "",

  crawl_status: 0,
  generate_status: 0
});

const isLoading = ref(false);

const handleReturn = () => {
  router.back();
};

//const process_response = async( resp) =>
//{
  //console.log( "***** process_response > ws_resp:", resp)

  // switch ( resp[ "q_type"])
  // {
  //   case "crawl_status":
  //     if( resp[ "is_success"]) {
  //       item.crawl_status = 2
  //       item.company_detail = resp["answer"]
  //
  //       await update_generate_status()
  //     }
  //     else {
  //       item.crawl_status = 3
  //
  //       await update_data()
  //     }
  //     break
  //
  //   case "generate_status":
  //     if( resp[ "is_success"]) {
  //       item.generate_status = 2
  //       item.mail_text = resp["answer"]
  //     }
  //     else {
  //       item.generate_status = 3
  //     }
  //
  //     await update_data()
  //     break
  // }
//}

// const createWebSocketConnection = async( ) =>
// {
//   const wsId = Math.random().toString(32).substring(2)
//   const ws = new WebSocket(
//       `${process.env.VUE_APP_WEBSOCKET_URL}/ws/${wsId}`)

//   ws.onmessage = ( event) => {
//     ( async () => {
//       try
//       {
//         console.log( event);

//         const ws_resp = JSON.parse(event.data);

//         if( ws_resp.error) {
//           // this.isLoading = false;
//           // this.showModal = true;
//           // this.show_messages = false;
//         }
//         else
//         {
//           // *** the main process of return value ***
//           await process_response( ws_resp);
//         }
//       }
//       catch( error)
//       {
//         console.error('Error processing WebSocket message:', error);

//         // 必要に応じてエラーハンドリングをここに追加
//         // (如果需要，請在此處新增錯誤處理)

//         // this.isLoading = false;
//         // this.showModal = true;
//         // this.show_messages = false;
//       }
//       finally {
//         ws.close();
//       }
//     })();
//   };

//   return wsId;
// }

const create_data = async( ) =>
{
  //const user = await userApi.whoami()
  //const tenant_id = user.data.tenant_id
  //console.log( "user:", user)
  //console.log( "tenant_id:", tenant_id)

  const _dict = {
    sales_list_id : sales_list_id,
    //tenant_id     : tenant_id,

    company_name  : item.company_name,
    person_name   : item.person_name,
    company_url   : item.company_url,
    email         : item.email,
    company_detail: item.company_detail,
    mail_text     : item.mail_text,

    tel: "",
    contact_url: "",
    target_flag : false,

    crawl_status: item.crawl_status,
    generate_status: item.generate_status,
    send_status: 0,
    check_status: 0,
  }

  const resp_create = await salesListsApi.createSaleslist_detail( _dict, locale.value);
  console.log( "resp_create:", resp_create)
  if( resp_create.status !== 200) {
    throw new Error("create new sales_lists_detail ERROR");
  }

  // isLoading.value = false;
  await router.push(`/forsales/sales_lists/${sales_list_id}/sales_lists_details`)
}

const handle_create = async (e) =>
{
  e.preventDefault()
  isLoading.value = true

  // await update_crawl_status()
  // FORSALES/FIX/SEND_MAIL:1. Insert data
  await create_data()

  isLoading.value = false
}

</script>

<template>
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>

  <div>
    <h3>{{ $t('NewSalesListsDetailsView.h3') }}</h3>
  </div>
  <br />

<!--  company_name-->
  <label for="company_name" class="form-label">
    {{ $t('NewSalesListsDetailsView.company_name') }} </label>
  <div class="input-group mb-3">
    <input type="text" class="form-control" id="company_name"
           v-model="item.company_name">
  </div>

<!--  person_name-->
  <label for="person_name" class="form-label">
    {{ $t('NewSalesListsDetailsView.person_name') }} </label>
  <div class="input-group mb-3">
    <input type="text" class="form-control" id="person_name" v-model="item.person_name">
  </div> 

<!--  company_url-->
  <label for="company_url" class="form-label">
    {{ $t('NewSalesListsDetailsView.company_url') }} </label>
  <div class="input-group mb-3">
    <input type="text" class="form-control" id="company_url"
           v-model="item.company_url">
  </div>

<!--  email-->
  <label for="email" class="form-label">
    {{ $t('NewSalesListsDetailsView.email') }} </label>
  <div class="input-group mb-3">
    <input type="text" class="form-control" id="email"
           v-model="item.email">
  </div>

<!--  company_detail-->
  <label for="company_detail" class="form-label">
    {{ $t('NewSalesListsDetailsView.company_detail') }} </label>
  <div class="input-group mb-3">
    <textarea id="company_detail" class="form-control"
              v-model="item.company_detail" rows="8"></textarea>
  </div>

<!--  mail_text-->
  <label for="mail_text" class="form-label">
    {{ $t('NewSalesListsDetailsView.mail_text') }} </label>
  <div class="input-group mb-3">
    <textarea id="mail_text" class="form-control"
              v-model="item.mail_text" rows="8"></textarea>
  </div>

  <br />
  <br />

  <div class="d-grid gap-2 d-md-flex justify-content-md-end">

    <button class="btn btn-primary" type="button" @click="handleReturn">
      {{ $t('NewSalesListsDetailsView.return')}}</button>

    <button class="btn btn-primary" type="button" @click="handle_create($event)">
      {{ $t('NewSalesListsDetailsView.update')}}</button>

  </div>

</template>

<style scoped>
label {
  margin-right: 2rem;
}

.settings-item {
  margin: 3rem 0;
}

.settings-field {
  margin: 10px 5px;
  padding: 0 1rem;
}

input {
  width: 40%;
}

.settings {
  width: 70%;
  margin: 0 auto;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.settings-buttons button {
  border: none;
  width: 20%;
  border-radius: 0;
}

.btn-new {
  margin-right: 50px;
}
</style>
